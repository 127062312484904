.slider-item {
  width: 100px;
  position: relative;
}

.deleteEntity {
  width: 12px;
  height: 12px;
  margin-left: 7px;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
}

.downloadEntity_svg {
  width: 30px;
  height: 30px;
}

.downloadEntity {
  position: absolute;
  top: 33px;
  right: 33px;
  display: none;
  background: none;
  outline: none;
  border: none;
}

.deleteEntity .deleteEntity_svg {
  width: 12px;
  height: 12px;
}

.slider-item:hover .deleteEntity, .slider-item:hover .downloadEntity {
  display: block;
  z-index: 10;
}

.slider-image {
  transition: 0.3s;
}

.slider-item:hover .slider-image {
  opacity: 0.2;
}

.deleteEntity .deleteEntity_svg path {
  fill: #d62323;
}