th.sortable-column {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

th.sortable-column .arrow-down, th.sortable-column .arrow-up {
    opacity: 0.3;
}

th.sortable-column-asc .arrow-down, th.sortable-column-desc .arrow-up {
    opacity: 1;
}