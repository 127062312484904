.address_creation_block {
  background-color: lightgrey;
  input {
    background-color: lightgrey;
    &::-webkit-input-placeholder {
      color: grey;
    }
  }
  box-sizing: content-box;
  .collapse_padding_workaround{
    padding: 1.5em;
  }
}

.boats-amount {
  padding: 0 10px;
}

input.boats-amount {
  background: transparent;
  border: none;
  outline: none;
  text-align: center;
  max-width: 52px;
}

.inactive_boat {
  background-color: #e9e8ee;
}