/* Icons */

.icons-list {
  border-left: 1px solid $border-color;
  border-top: 1px solid $border-color;
  >div {
    background: $white;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    @include display-flex;
    @include align-items(center);
    padding:15px 15px;
    font-weight: 400;
    font-size: $default-font-size;

    i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: theme-color(primary);
    }
  }
}

.icon-in-bg {
  width: 55px;
  height: 55px;
  text-align: center;
  @include display-flex();
  @include align-items(center);
  @include justify-content(center);
}

.fa,
.fas,
.far,
.fab {
  font-size: 14px;
  &.fa-xs { 
    font-size: .6rem;
  }	
  &.fa-sm	{
    font-size: .74rem;
  }	
  &.fa-md	{
    font-size: 1rem;
  }	
  &.fa-lg	{
    font-size: 1.33em;
    vertical-align: -25%;
  }
  &.fa-2x {
    font-size: 2rem;
  }	
  &.fa-3x {
    font-size: 3rem;
  }	
  &.fa-4x {
    font-size: 4rem;
  }	
  &.fa-5x {
    font-size: 5rem;
  }	
  &.fa-6x {
    font-size: 6rem;
  }	
  &.fa-7x {
    font-size: 7rem;
  }	
  &.fa-8x {
    font-size: 8rem;
  }	
  &.fa-9x {
    font-size: 9rem;
  }	
  &.fa-10x {	
    font-size: 10rem;
  }  	
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

.icon-xs {
  font-size: 0.562rem;
}


@each $color, $value in $theme-colors {
  .icon-rounded-#{$color} {
    background: rgba($value, .11);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    text-align: center;
    color: $value;
    i {
      font-size: 1.35rem;
      line-height: 55px;
    }
    &.icon-rounded-sm {
      width: 40px;
      height: 40px;
      i {
        font-size: .875rem;
        line-height: 40px;
      }
    }
    &.icon-rounded-lg {
      width: 70px;
      height: 70px;
      i {
        font-size: 2rem;
        line-height: 70px;
      }
    }
  }
}



