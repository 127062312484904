/* Rating */

.br-theme-fontawesome-stars,
.br-theme-css-stars {
  .br-widget {
    a {
      font-size: 20px;
      font-family: 'Font Awesome 5 Free';
      &.br-selected,
      &.br-active {
        &:after {
          color: theme-color(primary);
        }
      }
    }
  }
}

.br-theme-bars-1to10,
.br-theme-bars-movie,
.br-theme-bars-pill,
.br-theme-bars-reversed,
.br-theme-bars-horizontal {
  .br-widget {
    a {
      background-color: rgba(theme-color(primary), .6);
      color: theme-color(primary);
      &.br-selected,
      &.br-active {
        background-color: theme-color(primary);
      }
    }
  }
}

.br-theme-bars-square {
  .br-widget {
    a {
      border-color: rgba(theme-color(primary), .6);
      color: rgba(theme-color(primary), .6);
      &.br-selected,
      &.br-active {
        border-color: theme-color(primary);
        color: theme-color(primary);
      }
    }
  }
}

.br-theme-bars-movie,
.br-theme-bars-reversed,
.br-theme-bars-1to10,
.br-theme-bars-horizontal {
  .br-widget {
    .br-current-rating {
      color: $body-color;
      font-size: $default-font-size;
      font-weight: initial;
    }
  }
}

.br-theme-bars-1to10 {
  height: 26px;
  .br-widget {
    height: 24px;
    .br-current-rating {
      line-height: 2;
    }
  }
}

.br-theme-bars-pill {
  .br-widget {
    a {
     line-height: 2.5;
    }
  }
}