/* Dropdowns */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "Font Awesome 5 Free";
      content: "\f078";  
      font-weight: 600;
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: .625rem;
      font-size: .7rem;
    }
  }
  .dropdown-menu {
    margin-top: 0;
    font-size: $default-font-size;
    box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35); 
    border-radius: 0;   
    .dropdown-item {
      font-size: .875rem;
      padding: .25rem 1.5rem;
      &:active {
        background: initial;
      }
    }
  }
}
