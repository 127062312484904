/* Badges */

.badge {
  border-radius: 4px;
  font-size: 14px;
  line-height: 1;
  padding: .5rem .8rem;
  font-weight: normal;
  &.badge-pill {
    border-radius: 10rem;
  }
  &.badge-lg {
    padding: .8rem .7rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variations($value);
  }
}