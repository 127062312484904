.btn-outline-success {
  &:hover,
  &:active {
    color: #fff;
    background-color: #f35958;
    border-color: #f35958;
  }
}
.btn-outline-danger {
  &:hover,
  &:active {
    color: #fff;
    background-color: #0acf97;
    border-color: #0acf97;
  }
}

.is-added-new-order {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -8px;
    right: -8px;
    bottom: -16px;
    top: -16px;
    border: 2px solid #6e7aff;
  }
}