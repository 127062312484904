.pay-percent {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.experience-badge {
  padding: 0.08rem 0.25rem;
  margin-left: 0.2rem;
  &.badge {
    border: 1px solid #0acf97;
  }
}

.experience-divider {
  margin-top: 0.7rem;
  margin-bottom: 0.5rem;
}

.experience-block-ru {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.experience-block-en {
  display: flex;
  align-items: center;
  margin: 10px 0;
}