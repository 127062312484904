.title-underlined {
  text-decoration: underline;
}

.nav-link {
  cursor: pointer;
}

.nav-link:not(.active) {
  padding: 0.45rem 1.5rem;
}

.boats-amount-icon {
  cursor: pointer;
}

.dropdown-icon {
  margin-right: 5px;
}

.opacity-50 {
  opacity: .5
}

.pointer-events-none {
  pointer-events: none;
}

.z-2 {
  z-index: 2!important;
}