.totalRow th {
    padding: .5rem;
    font-size: 14px;
}

.data-table-content {
    min-height: 30px;
}
.model {
    padding: 3px !important;
}
.with_pointer {
    cursor: pointer;
}
.cancel-nowrap {
    white-space: normal !important;
}
.padding {
    padding: 0 !important;
}
.dealer_padding {
    padding: 5px !important;
}

.btn-group_top {
    top: -8px;
}

.small-width {
    width: 50px;
}

.small-padding {
    padding: .3rem!important;
}

.table-hover {
    cursor: pointer;
}

.w-45 {
 width: 45% !important;
}