.edit-modal {
  max-width: 1000px;

  .modal-footer {
    justify-content: space-between;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .edit-modal {
    max-width: 70%;
  }
}


// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .edit-modal {
    max-width: 80%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .edit-modal {
    max-width: 90%;
  }
}