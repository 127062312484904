.wrapper-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}
.indicator {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 100%;
}
.indicator-green{
  background: #0acf97;
}
.indicator-yellow{
  background: #f6e84e;
}
.indicator-red{
  background: rgb(224, 0, 0);
}
.wrapper-legend {
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: space-between;
}
.legend-paragraph {
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: .1fr 1fr;
  gap: 5px;
}
.legend-span {
  margin-top: 7px;
}
.legend-title {
  font-weight: bold;
  text-align: center;
}