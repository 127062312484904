.row-hover-test:hover {
  background-color: #eaeaf1;
}

.table {
  tbody {
    .arrivals-row {
      &.custom-blue {
        background-color: #f3f2f8;
      }
      &.custom-white {
        background-color: #fff;
      }
      &.linked {
        td {
          border-top: none;
          padding-top: 5px;
        }
      }
      &.has-linked {
        td {
          border-bottom: none;
          padding-bottom: 5px;
        }
      }
      &:hover {
        background-color: #eaeaf1;
      }
      td {
        padding: 10px;
      }
    }
  }
}