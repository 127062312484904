.loader-wrap {
  position: relative;
}

.loader-overlay {
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background: #fff;
  z-index: 2000;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out; /* Safari */
  visibility: hidden;
}

.loader-overlay.visible {
  opacity: 1;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out; /* Safari */
  visibility: visible;
}

.loader-overlay .loader-spinner {
  position: absolute;
  top:50%;
  left: 50%;
  width: 100px;
  height: 100px;
}

.loader-overlay .loader-spinner .flip-square-loader {
  position: absolute;
  top: -50px;
  left:-50px;
}